import { apiClient as client } from '@/shared/service/api-client';

export default {
    async get() {
        return await client.get('/corporation/get');
    },
    async getSurplusAccountNum() {
        return await client.get('/corporation/getSurplusAccountNum');
    },

    // 同步部门
    async asyncDepartments() {
        return await client.get('/corporation/asyncDepartments');
    },
    async getLastSyncTime(params) {
        return await client.get('/corporation/getLastSyncTime', { params });
    },

    // 获取agentConfig
    async getWorkwechatJsSDKAgentConf(params) {
        return await client.get('/corporation/getWorkwechatJsSDKAgentConf', { params });
    },
}
