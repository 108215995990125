import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/shared/styles/fonts/iconfont.js'
import * as antIcons from '@ant-design/icons-vue'

// 这里只引入几乎每个页面都用到的通用组件，非通用组件请在页面中手动引入
import { Tag, ConfigProvider, Spin, Divider, Dropdown, Layout, Modal, Table, Popconfirm, Drawer, Menu, Button, Row, Col, Form, Input, Alert, Card, message, Select, Upload } from 'ant-design-vue';
import { apiClient, apiStore } from "@/shared/service/api-client";
import filters from "@/shared/service/filters";

import "@/shared/styles/antdv-extend.less";
import '@/styles/tailwindcss.css'
import "@/styles/app.less";
import './assets/tailwind.css'

import Directives from '@/utils/directive/index'

apiClient.defaults.baseURL = '/api/console';
apiStore.initAuth();

// 为菜单栏动态引入icon
const app = createApp(App);
// 注册组件
Object.keys(antIcons).forEach(key => {
  app.component(key, antIcons[key])
})
// 添加到全局
app.config.globalProperties.$antIcons = antIcons

app.use(ConfigProvider);
app.use(Spin);
app.use(Dropdown);
app.use(Divider);
app.use(Layout);
app.use(Modal);
app.use(Menu);
app.use(Table);
app.use(Popconfirm);
app.use(Drawer);
app.use(Row);
app.use(Col);
app.use(Form);
app.use(Input);
app.use(Button);
app.use(Alert);
app.use(Card);
app.use(Tag);
app.use(Select);
app.use(Upload);

app.config.globalProperties.$message = message;
app.config.globalProperties.$f = filters;

app.use(Directives).use(router).mount('#app')
