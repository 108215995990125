import dayjs from "dayjs";
import filters from "@/shared/service/filters";
import useClipboard from "vue-clipboard3";
import { message } from "ant-design-vue";
import Duration from 'dayjs/plugin/duration';
import _ from 'lodash';

dayjs.extend(Duration);

// Enum
const userTypeEnum = {
  WECHAT: 1,
  CORP: 2,
};

const appendixTypeEnum = {
  TEXT: 'text',
  IMAGE: 'img',
  FILE: 'file',
  LINK: 'link',
  PDF: 'pdf',
};

const roleEnum = {
  ADMIN: 'ROLE_ADMIN',
  DEPT: 'ROLE_DEPARTMENT',
  STAFF: 'ROLE_STAFF',
}

// 返回当前浏览器环境
const browserEnv = (() => {
  // Mobile corpWechat || Mobile wechat || Mobile browser
  // PC corpWechat || PC wechat || PC browser

  const ua = window.navigator.userAgent;
  const device =
    /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
      ua.toLowerCase()
    )
      ? "Mobile"
      : "PC";

  if (_.includes(ua, "wxwork")) {
    return device + " corpWechat";
  }
  if (_.includes(ua, "MicroMessenger")) {
    return device + " wechat";
  }

  return device + " browser";
})();

// 计算文件大小
function renderSize(filesize) {
  if (null == filesize || filesize == "") {
    return "0 Bytes";
  }
  var unitArr = new Array("Bytes", "KB", "MB");
  var index = 0;
  var srcsize = parseFloat(filesize);
  index = Math.floor(Math.log(srcsize) / Math.log(1024));
  var size = srcsize / Math.pow(1024, index);
  size = size.toFixed(2); //保留的小数位数
  return size + unitArr[index];
}

// 计算最近回复时间
function computeLastReplyTime(msgTime) {
  if (!msgTime) {
    return "";
  }

  const nowDate = dayjs();
  const msgDate = dayjs(msgTime);
  const todaylc = dayjs().startOf("date");

  if (msgDate.isBefore(todaylc)) {
    const yesterday = todaylc.subtract(1, "day");

    return msgDate.isBefore(yesterday)
      ? filters.datetime(msgDate, "YYYY年MM月DD日")
      : "昨天";
  } else {
    const hours = nowDate.diff(msgDate, "hour");
    if (hours) {
      return hours + "小时前";
    }

    const minute = nowDate.diff(msgDate, "minute");
    if (minute) {
      return minute + "分前";
    }

    return "刚刚";
  }
}

// 将毫秒转换为天/时/分/秒
function timestampToHour(time) {

  let seconds = dayjs.duration(time).seconds(),
    minutes = dayjs.duration(time).minutes(),
    hours = dayjs.duration(time).hours();

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return { hours, minutes, seconds };
}

// 复制
async function copy(value) {
  const { toClipboard } = useClipboard();
  try {
    await toClipboard(value);
    message.success("复制成功");
  } catch {
    message.error("复制失败");
  }
}

// 下载图片到本地
function downloadImg(src, fileName) {
  let image = new Image();
  image.setAttribute("crossOrigin", "anonymous");
  image.src = src;
  image.onload = () => {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, image.width, image.height);
    const ext = "png";
    const dataURL = canvas.toDataURL("image/" + ext);
    const eleLink = document.createElement("a");
    eleLink.download = fileName + ".png";
    eleLink.href = dataURL;
    eleLink.click();
    eleLink.remove();
  };
}

// 格式化moment时间为开始时间与结束时间
function formatTimeForSearch(time) {
  if (!(time instanceof Array)) {
    throw new Error('formatTimeForSearch Error: 参数应为数组形式')
  }

  const searchForm = {
    startTime: '',
    endTime: '',
  }
  if (time.length > 0) {
    searchForm.startTime = dayjs(time[0]._d).startOf("date").valueOf();
    searchForm.endTime = dayjs(time[1]._d).endOf("date").valueOf();
  }
  return searchForm
}

// 配置企微agentConfig
import corporationApi from "@/service/api/corporation";

async function getWxAgentConfig() {
  const config = await corporationApi.getWorkwechatJsSDKAgentConf({
    authUrl: window.location.href,
  });

  window.wx.agentConfig({
    ...config,
    success: function () {
      window.WWOpenData.bindAll(document.querySelector(".ww-open-data"));
    },
    fail: function (res) {
      if (res.errMsg.indexOf("function not exist") > -1) {
        alert("版本过低请升级");
      }
    },
  });

  return window.wx
}

export {
  userTypeEnum,
  appendixTypeEnum,
  roleEnum,

  browserEnv,
  renderSize,
  computeLastReplyTime,
  timestampToHour,
  copy,
  downloadImg,
  formatTimeForSearch,
  getWxAgentConfig,
};