import axios from "axios";
import { TinyEmitter } from "tiny-emitter";

const apiBus = new TinyEmitter();

const apiStore = {
    token: "",
    user: null,

    setAuth(token, user) {
        localStorage.setItem("auth_token", token);
        localStorage.setItem("auth_user", JSON.stringify(user));
        this.token = token;
        this.user = user;
    },

    clearAuth() {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_user");
        this.token = "";
        this.user = null;
    },

    initAuth() {
        // 测试development  生产production
        if (process.env.NODE_ENV === 'development') {
            localStorage.setItem('auth_token', process.env.VUE_APP_AUTH_TOKEN)
            localStorage.setItem('auth_user', process.env.VUE_APP_AUTH_USER)
        }

        const token = localStorage.getItem("auth_token");
        try {
            if (token && localStorage.getItem("auth_user")?.length > 0) {
                const user = JSON.parse(localStorage.getItem("auth_user"));
                this.token = token;
                this.user = user;
            } else {
                this.token = "";
                this.user = null;
            }
        } catch {
            this.clearAuth()
            this.initAuth()
        }
    }
};

const apiClient = axios.create({
    timeout: 120000,
});

apiClient.interceptors.request.use(function (config) {
    if (apiStore.token.length > 0) {
        config.headers.Authorization = "Bearer " + apiStore.token;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

apiClient.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    const response = error.response;
    let err;
    if (response) {
        err = response.data;
        if (!err.code) {
            err = {
                timestamp: new Date().getTime(),
                status: response.status,
                code: "INVALID_ERROR_RESPONSE",
                message: "Invalid error response format.",
                path: error.config.url,
                traceId: "",
            }
        }
    } else {
        err = {
            timestamp: new Date().getTime(),
            status: 0,
            code: "CLIENT_ERROR",
            message: error.message,
            path: error.config.url,
            traceId: "",
        }
    }

    apiBus.emit("error", err);

    return Promise.reject(err);
});

export { apiClient, apiStore, apiBus };
