<template>
  <a-menu v-model:openKeys="currentMenu.openKeys"
          v-model:selectedKeys="currentMenu.selectedKeys"
          class="console-nav"
          mode="inline"
          @select="onMenuSelect">
    <template v-for="(menu, index) in realMenus">
      <template v-if="menu.subs">
        <a-sub-menu :key="index + ''"
                    disabled>
          <template #title>
            <div @click.stop>
              <component :is="$antIcons[menu.icon]" />
              <span class="text-14">{{ menu.name }}</span>
            </div>
          </template>
          <template #expandIcon>
            <span></span>
          </template>
          <a-menu-item v-for="(sub, subIndex) in menu.subs"
                       :key="index + '_' + subIndex"
                       class="relative">
            <span v-if="sub.new"
                  class="absolute -top-12 -right-16 text-14 text-danger font-medium">new</span>
            <router-link :to="sub.route">{{ sub.name }}</router-link>
          </a-menu-item>
        </a-sub-menu>
      </template>

      <template v-else>
        <a-menu-item :key="index + ''">
          <router-link :to="menu.route">
            <component :is="$antIcons['HomeOutlined']" />
            <span class="text-14">{{ menu.name }}</span>
          </router-link>
        </a-menu-item>
      </template>
    </template>

  </a-menu>
</template>

<script>
import router from "@/router/index";
import _ from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ConsoleNav",
  data() {
    return {
      realMenus: [
        {
          name: "首页",
          icon: "HomeOutlined",
          route: { name: "background_page" },
        },
      ],
      currentMenu: {
        openKeys: [],
        selectedKeys: [],
      },
    };
  },
  watch: {
    $route(to) {
      this.changeMenu(to);
    },
  },
  created() {
    this.initMenu();
  },
  methods: {
    initMenu() {
      const dynamicMenu = [];
      _.forEach(router.allRoutes, (parentMenu) => {
        // 如果父菜单的type为菜单栏
        if (parentMenu.type === "menu") {
          const menu = {
            name: parentMenu.name,
            icon: parentMenu.icon,
            subs: [],
          };
          if (parentMenu.children && parentMenu.children.length) {
            _.forEach(parentMenu.children, (childMenu) => {
              if (childMenu.type === "menu_item") {
                const child = {
                  name: childMenu.meta.title,
                  route: { name: childMenu.name },
                  new: childMenu.meta.status,
                };
                menu.subs.push(child);
              }
            });
            dynamicMenu.push(menu);
          }
        }
      });
      this.realMenus.push(...dynamicMenu);
      const arr = [];
      for (let i = 0; i < this.realMenus.length; i++) {
        arr.push(String(i));
      }
      this.currentMenu.openKeys = arr;

      this.changeMenu(this.$route);
    },
    onMenuSelect({ selectedKeys }) {
      this.currentMenu.selectedKeys = selectedKeys;
    },
    changeMenu(route) {
      for (let i = 0; i < this.realMenus.length; i++) {
        const menu = this.realMenus[i];
        let selectedKey = i + "";
        if (_.isArray(menu.subs)) {
          for (let j = 0; j < menu.subs.length; j++) {
            const sub = menu.subs[j];
            if (sub.route.name === route.name) {
              selectedKey += "_" + j;
              this.currentMenu.selectedKeys = [selectedKey];
            }
          }
        } else {
          if (menu.route.name === route.name) {
            this.currentMenu.selectedKeys = [selectedKey];
            break;
          }
        }
      }
    },
  },
});
</script>
