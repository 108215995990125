import _ from 'lodash'
export default {

  mounted: function (el, binding) {
    const {
      nodeName
    } = el;

    const params = {
      method: null,
      delay: 800
    }

    if (_.isPlainObject(binding.value)) {
      _.assign(params, binding.value)
    } else {
      params.method = binding.value
    }

    if (nodeName === 'BUTTON') {
      el.addEventListener('click',
        _.debounce(params.method, params.delay)
      )
      return
    }

    if (_.includes(el.className, "ant-input-search")) {
      el.children[0]?.addEventListener('input',
        _.debounce(params.method, params.delay)
      )

      return
    }

    if (nodeName === 'INPUT') {
      el.addEventListener('input',
        _.debounce(params.method, params.delay)
      )
    }
  },
}
