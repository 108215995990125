import { copy } from '@/global'

const copyMethod = (msg) => {
  copy(msg)
}

export default {
  mounted: function (el, binding) {
    el.addEventListener('click', copyMethod.bind(null, binding.value))
  },
  beforeUnmount: function (el) {
    el.removeEventListener('click', copyMethod)
  }
}
