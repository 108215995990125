<template>
    <router-view />
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: 'BlankLayout'
})
</script>
