import debounce from './debounce'
import copy from './copy'

// 自定义指令
const directives = {
  debounce,
  copy
}

export default {
  install(app) {
    Object.keys(directives).forEach((key) => {
      app.directive(key, directives[key])
    })
  },
}