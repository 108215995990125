<template>
  <a-layout class="console-layout__section"
            style="min-height: 100vh">
    <a-layout-sider id="sider"
                    class="fixed left-0 h-full"
                    width="200px">
      <div class="app-name">
        <img class="logo mr-8 w-24"
             src="@/assets/ESlogo.png"
             alt="公司logo"> EduSoho 销客助手
      </div>
      <console-nav></console-nav>
    </a-layout-sider>
    <a-layout style="margin-left: 200px">
      <a-layout-header class="header">
        <span class="current-title">{{title}}</span>

        <div class="flex ">
          <a class="mr-32 hover:text-admin-primary text-color-666 cursor-pointer"
             href="https://scrm-docs.edusoho.com/"
             target="_blank">
            <svg-icon class="ml-4 icon-16"
                      type="iconbangzhu" />
            帮助文档
          </a>

          <div class="current-user">
            <img class="current-avatar"
                 :src="user.avatar"
                 alt="用户头像">
            <!-- {{ user.username }} -->
            <div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="userName"
                 :openid="user.username"></div>
            <a @click="onClickLogout"
               class="logout-btn">退出</a>
          </div>
        </div>

      </a-layout-header>
      <a-layout-content class="m-16 p-24 h-full max-h-full bg-color-f"
                        style="overflow: initial">
        <router-view></router-view>
      </a-layout-content>

    </a-layout>
  </a-layout>
</template>

<script>
import { apiStore, apiBus } from "@/shared/service/api-client";
import ConsoleNav from "@/components/ConsoleNav";
import { defineComponent } from "vue";
import _ from "lodash";
import SvgIcon from "@/components/SvgIcon";

export default defineComponent({
  name: "ConsoleLayout",
  components: {
    ConsoleNav,
    SvgIcon,
  },
  data() {
    return {
      user: apiStore.user,
      title: "",
    };
  },
  created() {
    apiBus.on("error", (err) => {
      if (err.status === 401) {
        this.$message.error(err.message);
        apiStore.clearAuth();
        this.$router.push({ name: "home_login" });
        return;
      }

      if (err.status === 403) {
        this.$router.push({ name: "403" });
        return;
      }

      const whiteList = ["40390044", "50390001", "40390005", "40390058"];

      if (_.includes(whiteList, err.code)) {
        return;
      }

      this.$message.error(err.message);
    });
    this.title = this.$route.meta.title;
  },
  methods: {
    onClickLogout() {
      apiStore.clearAuth();
      this.$router.push({ name: "home_login" });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.title = to.meta.title;
    next();
  },
  beforeRouteUpdate(to, from, next) {
    this.title = to.meta.title;
    next();
  },
});
</script>

