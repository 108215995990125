<template>
  <svg class="svg-icon"
       :style="{fontSize: size + 'px'}"
       aria-hidden="true">
    <use :xlink:href="`#${type}`" />
  </svg>

</template>

<script>
export default {
  name: "SvgIcon",

  props: {
    type: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
    },
  },
};
</script>

<style lang="less" scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  &:focus {
    outline: none;
  }
}
</style>
