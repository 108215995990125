import { createRouter, createWebHistory } from 'vue-router';
import BlankLayout from "@/components/BlankLayout.vue";
import ConsoleLayout from "@/components/ConsoleLayout.vue";
import { apiStore } from "@/shared/service/api-client";
import menuApi from '@/service/api/menu';
import _ from 'lodash';

function filterAsyncRouter(asyncRouterMap) {
    const accessedRouters = asyncRouterMap.filter(route => {
        if (route.component) {
            if (route.component === 'BlankLayout') {
                route.component = BlankLayout
            } else if (route.component === 'ConsoleLayout') {
                route.component = ConsoleLayout
            } else {
                route.component = loadView(route.component);
            }
        }
        if (route.children && route.children.length > 0) {
            route.children = filterAsyncRouter(route.children)
        }
        return true
    })
    return accessedRouters;
}
const loadView = (view) => { // 路由懒加载
    return () => import('@/views/' + view)
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [],
});

const staticRouters = [
    {
        path: '/',
        name: 'Home',
        redirect: "/index",
        component: BlankLayout,
        children: [
            {
                path: "home_login",
                name: "home_login",
                component: () => import(/* webpackChunkName: "Home" */ "../views/workwechat/LoginAuthOwn.vue"),
                meta: {
                    title: '企业微信授权',
                }
            },
            {
                path: "work_wechat_auth",
                name: "work_wechat_auth",
                component: () => import(/* webpackChunkName: "Home" */ "../views/workwechat/LoginAuth.vue"),
                meta: {
                    title: '企业微信授权',
                }
            },
            {
                path: "work_wechat_auth_err",
                name: "work_wechat_auth_err",
                component: () => import(/* webpackChunkName: "Home" */ "../views/workwechat/LoginError.vue"),
                meta: {
                    title: '企业微信异常',
                }
            },
        ],
    },
    {
        path: "/auth",
        name: "Login",
        component: BlankLayout,
        redirect: "/auth/login",
        children: [
            {
                path: "login",
                name: "auth_login",
                component: () => import("../views/Login.vue"),
                meta: {
                    title: '登录',
                }
            },
        ],
    },
    {
        path: "/error",
        name: "error",
        component: BlankLayout,
        redirect: "/403",
        children: [
            {
                path: "403",
                name: "403",
                component: () => import("../views/errorPage/403.vue"),
                meta: {
                    title: '没有权限访问',
                }
            },
            {
                path: "404",
                name: "404",
                component: () => import("../views/errorPage/404.vue"),
                meta: {
                    title: '此页面未找到',
                }
            },
        ],
    },
    {
        path: "/index",
        component: ConsoleLayout,
        redirect: "/index/background",
        children: [
            {
                path: "background",
                name: "background_page",
                component: () => import("../views/Index.vue"),
                meta: {
                    title: '首页',
                }
            },
        ],
    },
    {
        path: "/live/enter",
        name: "live_enter",
        component: () => import("../views/liveOpenClass/liveClass/LiveClassEnter.vue"),
        meta: {
            title: '进入直播间',
        }
    },
]
router.allRoutes = [...staticRouters]
let dynamicRouters = [];

_.forEach(staticRouters, (staticRouter) => {
    router.addRoute(staticRouter)
})
router.beforeEach(async (to, from, next) => {
    const whiteList = ['work_wechat_auth_err', 'home_login', 'work_wechat_auth', 'live_enter']
    if (_.includes(whiteList, to.name)) {
        next();
        return
    }
    if (to.name !== "home_login" && !apiStore.user) {
        next({ name: "home_login" });
        return
    }
    if (!dynamicRouters.length) {
        try {
            const res = await menuApi.getMenus();
            dynamicRouters = res.menu;
            const menu = filterAsyncRouter(dynamicRouters)
            _.forEach(menu, (dynamicRouter) => {
                router.addRoute(dynamicRouter);
            })
            router.allRoutes.push(...dynamicRouters)
            next({ ...to, replace: true })
        } catch (err) {
            if (err.status === 401) {
                apiStore.clearAuth();
                next({ name: "home_login" });
            }
        }
        return
    }

    next();
});
export default router